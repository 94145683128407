<template>
  <transition name="fade" mode="out-in">
    <div>
      <article>
        <h1>Beach Session</h1>
        <div v-if="response">
          {{ response.message }}
        </div>
      </article>
      <slot />
    </div>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "SingUp",
  metaInfo() {
    return {
      title: "Beach Session",
    };
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      response: null,
    };
  },
  mounted() {
    const query = this.$route.query;

    if (query.mode === "confirm") {
      const options = {
        method: "PUT",
        url: `${this.api}/sign-up/${query.id}/${query.token}`,
      };

      axios
        .request(options)
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (query.mode === "sign-off") {
      const options = {
        method: "DELETE",
        url: `${this.api}/sign-up/${query.id}/${query.token}`,
      };

      axios
        .request(options)
        .then((response) => {
          this.response = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      this.$router.push({ name: "Error" });
    }
  },
};
</script>